<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img
            alt="logo"
            height="60"
            src="@/assets/images/logo/logo.svg"
        >

      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          class="d-none d-lg-flex align-items-center p-5"
          lg="8"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              :src="sideImg"
              alt="Forgot password V2"
              fluid
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="4"
      >
        <b-col
            class="px-xl-2 mx-auto"
            lg="12"
            md="6"
            sm="8"
        >
          <b-card-title
              class="font-weight-bold mb-1"
              title-tag="h2"
          >
            Mot de passe oublié? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Entrez votre Identifiant et nous vous enverrons des instructions pour réinitialiser votre mot de passe
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForm"
            >
              <b-form-group
                  label="Identifiant"
                  label-for="forgot-password-username"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Identifiant"
                    rules="required"
                >
                  <b-form-input
                      id="forgot-password-email"
                      v-model="userName"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-email"
                      placeholder="votre Identifiant"
                  />
                  <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                  block
                  type="submit"
                  variant="primary"
              >
                Envoyer le lien de Réinitialisation
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon"/>
              Retour Connexion
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BButton, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BImg, BLink, BRow, } from 'bootstrap-vue'
import { email, required } from '@validations'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userName: '',
      sideImg: require('@/assets/images/login/login_slide.png'),
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.$http
                  .put(`SendPasswordReset/${this.userName}`)
                  .then(response => {
                    if (response.data.success) {
                      this.messageToast(response.data.message, 'Succès', 'success', 'BellIcon', 6000)
                      this.$router.push('/login')
                    }
                  })
                  .catch(err => {
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                  })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
